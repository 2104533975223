import React from "react";
import styles from '../../assets/scss/components/chats.module.scss';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

const NoChats = () => {
    return(
        <>
            <div className={styles.no__chats__div}>
                <WhatsAppIcon sx={{fontSize: '100px', marginBottom: '20px', color: '#25D366'}} />
                <div>
                    <h4>To display messages, select a chat.</h4>
                    <p>To initiate a conversation with a user, click on any chat.</p>
                </div>
            </div>
        </>
    )
}

export default NoChats;