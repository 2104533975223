import React, { useState, useRef, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import { openRescheduleBroadcastBoxState } from '../../store/broadcasts';
import { AlertOpenState, confirmationDialogState } from '../../store/journey-list';
import { useRecoilState } from 'recoil';
import styles from '../../assets/scss/page/broadcasts.module.scss';
import { styled } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';
import AlertDialog from '../../components/basic-components/alert-dialog';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import CancelIcon from '@mui/icons-material/Cancel';
import SendIcon from '@mui/icons-material/Send';
import InputLabel from '@mui/material/InputLabel';
import ClearIcon from '@mui/icons-material/Clear';
import moment from 'moment';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import axiosInstance from '../../plugins/axios';

const ClearIconButton = styled(IconButton)(({ theme }) => ({
    '&:hover': {
        backgroundColor: 'transparent',
    },
}));

const disablePast = (date) => {
    return moment().isAfter(date) ? true : false;
};

const timeOptions = [
    '09:00', '09:30', '10:00', '10:30', '11:00', '11:30', '12:00',
    '14:00', '15:30', '16:00', '16:30', '17:00', '17:30', '18:00', '18:30', '19:00',
    '20:00', '20:30', '21:00'
];

const RescheduleBroadcast = ({broadcastData, setBroadcastData}) => {
    const [openDialog, setOpenDialog] = useRecoilState(openRescheduleBroadcastBoxState);
    const [alertOpen, setAlertOpen] = useRecoilState(AlertOpenState);
    const [scheduledDate, setScheduledDate] = useState(null);
    const [scheduledTime, setScheduledTime] = useState('');
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [confirm, setConfirm] = useRecoilState(confirmationDialogState);

    const theme = useTheme();
    const inputRef = useRef(null);

    // Effect to set the initial date and time from broadcastData
    useEffect(() => {
        try {
            if (broadcastData?.start_date) {
                setScheduledDate(moment(broadcastData.start_date));
                setScheduledTime(moment(broadcastData.start_date).format('HH:mm'));
            }
        } catch (error) {
            console.log(error);
        }
    }, [broadcastData]);

    // Effect to manage the state of the isButtonDisabled
    useEffect(() => {
        const checkButtonDisable = () => {
            if (!scheduledDate || !scheduledTime) {
                return true;
            }
            return isDateTimeInPast(scheduledDate, scheduledTime);
        };
        setIsButtonDisabled(checkButtonDisable());
    }, [scheduledDate, scheduledTime]);

    const handleClose = () => {
        setOpenDialog(false);
    };

    const isDateTimeInPast = (date, time) => {
        const selectedDateTime = moment(`${moment(date).format('YYYY-MM-DD')} ${time}`, 'YYYY-MM-DD HH:mm');
        return moment().isAfter(selectedDateTime);
    };

    const onConfirmDialog = (e) => {
        e.preventDefault();
        setConfirm({
            open: true,
            title: 'Are you sure?',
            message: `Please confirm that you want to make changes?`,
            onConfirm: async () => await submitData(),
            onCancel: () => setConfirm({ ...confirm, title: 'Are you sure?', message: `Please confirm that you want to make changes?`, open: false }),
        });
    };

    const submitData = async () => {
        try {
            if (scheduledDate) {
                if (isDateTimeInPast(scheduledDate, scheduledTime)) {
                    setAlertOpen({ ...alertOpen, open: true, msgType: 'error', alertMsg: 'Selected date and time have already passed. Please choose a future date and time.' });
                    return;
                }
                let inputDate = moment(`${moment(scheduledDate).format('YYYY-MM-DD')} ${scheduledTime}`).format("ddd MMM DD YYYY HH:mm:ss [GMT]ZZ");
                const res = await axiosInstance.patch(`/api/v1/communications/whatsapp/campaigns/${broadcastData.id}/`, {
                    new_schedule_time: inputDate
                });
                if (res.status === 200) {
                    setBroadcastData({ ...broadcastData, start_date: inputDate });
                    setAlertOpen({ ...alertOpen, open: true, msgType: 'success', alertMsg: res.data.msg });
                    setOpenDialog(false);
                } else {
                    setAlertOpen({ ...alertOpen, open: true, msgType: 'error', alertMsg: res.data.msg });
                }
            }
            setConfirm({ ...confirm, open: false });
        } catch (error) {
            if(error.response.data.msg){
                setAlertOpen({ ...alertOpen, open: true, msgType: 'error', alertMsg: error.response.data.msg });
            }else{
                setAlertOpen({ ...alertOpen, open: true, msgType: 'error', alertMsg: 'Failed to reschedule broadcast. Please try again.' });
            }
            setConfirm({ ...confirm, open: false });
            console.error('Error fetching data:', error);
        }
    };

    const handleClearDate = () => {
        setScheduledDate(null);
        setScheduledTime('');
    };

    const setDate = (date) => {
        setScheduledDate(date);
    };

    const setTime = (event) => {
        setScheduledTime(event.target.value);
    };

    const isTimeDisabled = (time) => {
        if (!scheduledDate) return false;
        const selectedDate = moment(scheduledDate).startOf('day');
        const now = moment().startOf('day');
        if (selectedDate.isSame(now, 'day')) {
            const nowWithOffset = moment().add(30, 'minutes');
            const selectedTime = moment(time, 'HH:mm');
            return selectedTime.isBefore(nowWithOffset, 'minute');
        }
        return false;
    };

    return (
        <>
            <Dialog
                fullWidth={true}
                maxWidth='sm'
                open={openDialog.open}
                onClose={handleClose}
                style={{ height: '650px' }}
            >
                <div className={styles.dialog__container}>
                    <Stack direction="row" spacing={2} className='mt-1 d-flex justify-content-between align-items-center'>
                        <h4>{openDialog.title}</h4>
                        <div className="">
                            <IconButton onClick={handleClose}>
                                <CloseOutlinedIcon className={styles.close__btn} />
                            </IconButton>
                        </div>
                    </Stack>
                    <div>
                        <form noValidate autoComplete="off" onSubmit={onConfirmDialog} className='mt-2 w-100'>
                            <Stack direction="row" spacing={2}>
                                <div className="w-50 mt-2">
                                    <InputLabel id="demo-simple-select-label" className='pb-1' style={{ paddingLeft: '4px' }}>Schedule Date (Optional)</InputLabel>
                                    <FormControl fullWidth size="small">
                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                            <Stack direction="row" alignItems="center">
                                                <DatePicker
                                                    value={scheduledDate}
                                                    onChange={(newValue) => setDate(newValue)}
                                                    sx={{ '& .MuiInputBase-input': { padding: '8px' } }}
                                                    disablePast={disablePast}
                                                />
                                                {scheduledDate && (
                                                    <ClearIconButton onClick={handleClearDate} size="small">
                                                        <ClearIcon />
                                                    </ClearIconButton>
                                                )}
                                            </Stack>
                                        </LocalizationProvider>
                                    </FormControl>
                                </div>
                                <div className="w-50 mt-4">
                                    <FormControl variant="standard" fullWidth>
                                        <InputLabel id="time_broadcast">Time</InputLabel>
                                        <Select
                                            labelId="time_broadcast"
                                            id="time-broadcast-standard"
                                            value={scheduledTime}
                                            onChange={setTime}
                                            label="Time"
                                        >
                                            {timeOptions.map((time) => (
                                                <MenuItem key={time} value={time} disabled={isTimeDisabled(time)}>
                                                    {moment(time, 'HH:mm').format('hh:mm A')}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                            </Stack>
                            <Stack direction="row" spacing={2} className='mt-3 d-flex justify-content-end align-items-center'>
                                <Button variant="outlined" onClick={() => setOpenDialog({ open: false, type: '', title: '', id: '' })} className='d-flex justify-content-center align-items-center' endIcon={<CancelIcon className='mb-1' />}>
                                    Cancel
                                </Button>
                                <Button variant="contained" type='submit' endIcon={<SendIcon />} disabled={isButtonDisabled}>
                                    Reschedule
                                </Button>
                            </Stack>
                        </form>
                    </div>
                </div>
            </Dialog>
            <AlertDialog />
        </>
    );
};

export default RescheduleBroadcast;
